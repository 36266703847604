import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCqA4-AEqSDD_JdEkoAEi9XhP1fdv1yp3g",
  authDomain: "focus-9b4d7.firebaseapp.com",
  projectId: "focus-9b4d7",
  storageBucket: "focus-9b4d7.appspot.com",
  messagingSenderId: "757474259980",
  appId: "1:757474259980:web:2f027115485864b739ea29",
  measurementId: "G-ECCEKKYFG3",
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
