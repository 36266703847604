import "./App.css";
import { useEffect, useState } from "react";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Tag,
  Input,
} from "@chakra-ui/react";
import { Row } from "react-flexbox-grid";
import { db } from "./firebase";

const timeOptions = [
  "1min",
  "2min",
  "3min",
  "5min",
  "10min",
  "15min",
  "20min",
  "30min",
];

interface ITask {
  task: string;
  time: string;
  startTime: Date;
}

const docRef = doc(db, "tasks", "current");

const Task = () => {
  const [value, setValue] = useState("");
  const [time, setTime] = useState<string>("1min");

  useEffect(() => {
    const newTask: ITask = {
      task: value,
      time: time,
      startTime: new Date(),
    };
    if (value === "") return;
    setDoc(docRef, newTask);
  }, [time, value]);

  return (
    <div style={{ padding: 20 }}>
      <Row>
        <Input
          placeholder="Current Task"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Row>
      <Row style={{ paddingTop: 20 }}>
        {timeOptions.map((item) => (
          <Button
            style={{ marginRight: 10 }}
            key={item}
            colorScheme={time === item ? "blue" : "gray"}
            onClick={() => {
              setTime(item);
            }}
          >
            {item}
          </Button>
        ))}
      </Row>
    </div>
  );
};

const Focus = () => {
  const [task, setTask] = useState<ITask | null>(null);
  const [minutesRemaining, setMinutesRemaining] = useState(100);
  const [secondsRemaining, setSecondsRemaining] = useState(0);

  const setRemainingTime = () => {
    if (!task) return;
    const now = new Date();
    const diff = now.getTime() - task.startTime.getTime();
    const minutes = Math.floor(diff / 1000 / 60);
    const seconds = Math.floor((diff / 1000) % 60);
    setMinutesRemaining(parseInt(task.time) - minutes - 1);
    setSecondsRemaining(60 - seconds);
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(docRef, (doc) => {
      setTask({
        ...doc.data(),
        startTime: (doc.data() as any).startTime.toDate(),
      } as ITask);
      setRemainingTime();
    });
    return () => {
      unsubscribe();
    };
  }, [setRemainingTime]);

  useEffect(() => {
    if (!task) return;
    const interval = setInterval(() => {
      setRemainingTime();
    }, 1000);

    return () => clearInterval(interval);
  }, [task, setRemainingTime]);

  return (
    <div
      style={{
        padding: 20,
        textAlign: "center",
        alignContent: "center",
        justifyItems: "center",
      }}
    >
      {task && (
        <>
          <h1 style={{ fontSize: 80 }}>{task.task}</h1>
          <Tag
            style={{ fontSize: 12, padding: 5 }}
            colorScheme="blue"
            variant="solid"
          >
            {task.time}
          </Tag>
          <h2 style={{ fontSize: 30, marginTop: 40 }}>
            {minutesRemaining >= 0 ? (
              <>
                {minutesRemaining}:{secondsRemaining} remaining
              </>
            ) : (
              <>
                <Tag
                  colorScheme="green"
                  variant="solid"
                  style={{
                    height: 40,
                    width: 150,
                    textAlign: "center",
                    justifyContent: "center",
                    fontSize: 20,
                  }}
                >
                  Task Complete
                </Tag>
              </>
            )}
          </h2>
        </>
      )}
    </div>
  );
};

function App() {
  return (
    <>
      <Tabs>
        <TabList>
          <Tab>Task</Tab>
          <Tab>Focus</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Task />
          </TabPanel>
          <TabPanel>
            <Focus />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}

export default App;
